import Logo from '../../assets/ecotrade/logo.svg';
import Favicon from '../../assets/ecotrade/favicon.png';
import { Theme, ThemeDark } from '../../themes/ecotrade';
import { defaultConfig } from '../default-config';
import { Config } from '../types';

export const config: Config = {
  authConfig: {
    Keycloak: {
      ...defaultConfig.authConfig.Keycloak,
      clientId: 'reseller-ecotrade',
    },
  },
  dataConfig: {...defaultConfig.dataConfig, api: process.env.REACT_APP_ECOTRADE + '/api'},
  logo: Logo,
  favicon: Favicon,
  title: 'Ecotrade',
  theme: Theme,
  themeDark: ThemeDark,
  showIpex: defaultConfig.showIpex,
  showUvam: defaultConfig.showUvam,
  isLeafReseller: defaultConfig.isLeafReseller,
  currentReseller: 'ecotrade',
};
